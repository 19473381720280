import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["date", "loading"]
  static values = { interval: Number }

  initialize() {
    window.showGames = (data) => {
      let placement = document.querySelector("#scores")
      let games = data.games
      this.hideLoading();
      if (games.length > 0) {
        games.forEach(function (game) {

          let vTeam = game['vTeam']
          let hTeam = game['hTeam']
          let vTeamScores = vTeam.linescore.map(function(item) { return item.score })
          let hTeamScores = hTeam.linescore.map(function(item) { return item.score })

          placement.innerHTML +=
                                  `
                                    <article class="nba-card">
                                      <div class="border-b py-1">
                                        <div class="flex justify-between items-center">
                                          <div>${vTeam['triCode']}</div>
                                          <div class="inline">
                                            ${vTeamScores.join(" · ") + ' · ' + '<strong> ' + vTeam['score'] + '</strong>'}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="py-1">
                                        <div class="flex justify-between items-center">
                                          <div>${hTeam['triCode']}</div>
                                          <div class="inline">
                                            ${hTeamScores.join(" · ") + ' · ' + '<strong> ' + hTeam['score'] + '</strong>'}
                                          </div>
                                        </div>
                                      </div>
                                    </article>
                                  `

        })
      } else {
        placement.textContent = "No games on at the moment"
      }
    }
  }

  connect() {
    let datepicker = document.querySelector("#datepicker")
    let timer = document.querySelector("#timer")
    let today = new Date().toISOString().slice(0, 10)
    datepicker.value = today
    let date = today.replaceAll('-','')
    this.getScores(date)

    window.refreshScores = () => {
      this.clearContent();
      let today = new Date().toISOString().slice(0, 10)
      let date = today.replaceAll('-','')
      datepicker.value = today
      this.getScores(date)
    }
  }

  showLoading() {
    this.loadingTarget.classList.remove("hidden")
  }

  hideLoading() {
    this.loadingTarget.classList.add("hidden")
  }

  clearContent() {
    let placement = document.querySelector("#scores")
    placement.innerHTML = ""
  }

  dateSelect() {
    this.clearContent();
    let date = this.dateTarget.value.replaceAll('-','')
    this.getScores(date)
  }

  refresh() {
    window.refreshScores();
  }

  getScores(date) {
    if (this.loadingTarget.classList.contains('hidden')) {
      this.showLoading();
    }
    let url = `https://data.nba.net/10s/prod/v1/${date}/scoreboard.json`
    fetch(url)
      .then(response => response.json())
      .then(data => window.showGames(data))
  }
}
